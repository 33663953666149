import React from "react";
import { Link } from "react-router-dom";
import { GiCheckMark } from "react-icons/gi";

import SliderImageBootcampKubernetes from "../../../assets/img/bootcamps/01-Photo-Description-Bootcamp.png";

const SiteBreadcrumb = (props) => {
  return (
    <div class="big-wrapper">
      <div class="bootcamp-section main-header-comp bgcolor-midnight-gradient">
        <div className="wrapper-1600 flex-inline justify-start position-relative wrapp-on-phablet">
          <div className="col-60">
            <div className="mb-s2">
              <h2 className="sl-title responsive-title">
                Donnez un coup d'accélérateur à votre carrière Cloud Native avec
                notre Bootcamp Kubernetes
              </h2>
              <div
                data-hover="false"
                data-delay="200"
                className="text-dropdown w-condition-invisible w-dropdown"
              >
                <div className="text-dropdown-toogle w-dropdown-toggle">
                  <div className="h2-style text-gradient-cherry w-dyn-bind-empty"></div>
                </div>
                <nav className="text-dropdown-list w-dropdown-list">
                  <div>
                    <div className="w-dyn-list">
                      <div role="list" className="w-dyn-items">
                        <div role="listitem" className="ucfirst w-dyn-item">
                          <a
                            href="/program/devops-full-time-remote"
                            className="text-dropdown-link"
                          >
                            à distance
                          </a>
                        </div>
                        <div role="listitem" className="ucfirst w-dyn-item">
                          <a
                            href="/program/devops-full-time-paris"
                            className="text-dropdown-link"
                          >
                            Paris
                          </a>
                        </div>
                        <div role="listitem" className="ucfirst w-dyn-item">
                          <a
                            href="/program/devops-full-time"
                            aria-current="page"
                            className="text-dropdown-link w-dyn-bind-empty w--current"
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div>
              <div className="margin-bottom-32 mt-s1 w-richtext">
                <div className="w-embed">
                  <div className="p-style text-black no-margin">
                    <ul>
                      <li>
                        <p className="dese">
                          <GiCheckMark
                            style={{ marginRight: "8px", color: "#ff5421" }}
                          />
                          <strong>Le premier Bootcamp</strong> à consacrer{" "}
                          <strong>4 semaines</strong> exclusivement à la
                          maîtrise de Kubernetes
                        </p>
                      </li>
                      <li>
                        <p className="dese">
                          <GiCheckMark
                            style={{ marginRight: "8px", color: "#ff5421" }}
                          />
                          <strong>Projets Immersifs</strong> pour implémenter
                          Kubernetes <strong>On-Premises</strong> et dans le{" "}
                          <strong>Cloud</strong>
                        </p>
                      </li>
                      <li>
                        <p className="dese">
                          <GiCheckMark
                            style={{ marginRight: "8px", color: "#ff5421" }}
                          />
                          <strong>Préparation</strong> à une certification de
                          Kubernetes
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-horizontal"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "16px", // Adjust the gap between buttons as needed
              }}
            >
              <div className="sl-btn">
                <Link className="readon orange-btn main-home" to="#">
                  Nous contacter
                </Link>
              </div>

              <div className="sl-btn">
                <Link className="readon orange-btn main-home" to="#">
                  Télécharger le programme
                </Link>
              </div>
            </div>
          </div>
          <div className="bootcamp-header-image responsive-image">
            <img
              src={SliderImageBootcampKubernetes}
              loading="lazy"
              alt="Bootcamp Kubernetes"
              sizes="(max-width: 767px) 88vw, (max-width: 991px) 33vw, (max-width: 1919px) 28vw, 450px"
              className="contain-image"
            />
          </div>
        </div>

        <div class="lottie-anim">
          <div
            class="lottie-sprinkle-scalling full-size"
            data-w-id="96b37ed1-de7b-161b-48c9-d4ff8f133302"
            data-animation-type="lottie"
            data-src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/60ad2963221f39a76002453b_anim-fond-bleu.json"
            data-loop="1"
            data-direction="-1"
            data-autoplay="1"
            data-is-ix2-target="0"
            data-renderer="svg"
            data-default-duration="21"
            data-duration="45"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SiteBreadcrumb;
