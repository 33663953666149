import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import { Link } from "react-router-dom";

import devopsBootcampImg from "../../assets/img/menu/devops_bootcamp.png";
import kubernetesBootcampImg from "../../assets/img/menu/kubernetes_bootcamp.png";

const Bootcamps = () => {
  return (
    <div
      id="bootcamps"
      className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-80"
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title3 text-center mb-44"
          subtitleClass="sub-title"
          subtitle="NOS BOOTCAMPS"
          titleClass="title black-color"
          title="Des parcours conçus par nos formateurs, avec des projets immersifs et des scénarios d'entreprise basés sur leur expérience en Consulting dans l'IT pour propulser votre carrière "
        />
        <div className="row g-10 mb-34 justify-content-between">
          {/* First Div */}
          <div
            className="col-5 mb-30"
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
            }}
          >
            <div
              className="post-img"
              style={{
                flex: "0 0 30%",
                textAlign: "center",
              }}
            >
              <img src={devopsBootcampImg} alt="Devops" />
            </div>
            <div
              className="post-item"
              style={{
                flex: "1",
                paddingLeft: "15px",
              }}
            >
              <h5 className="title" style={{ fontSize: "1.5rem" }}>
                Ingénieur Cloud & DevOps
              </h5>
              <span>
                Formation intensive de 10 Semaines orientée projets, qui vous
                prépare aux compétences essentielles en architecture cloud et en
                automatisation DevOps
              </span>
              <div className="sl-btn" style={{ paddingTop: "20px" }}>
                <Link className="readon orange-btn main-home" to="#">
                  En savoir plus
                </Link>
              </div>
              {/* <div className="sm-btn" style={{ paddingTop: "20px" }}>
                <Link
                  className="readon orange-btn main-home"
                  to="#"
                  style={{ borderRadius: "0", border: "1px solid black" }}
                >
                  <span style={{ color: "black" }}>En savoir plus</span>
                </Link>
              </div> */}
            </div>
          </div>

          {/* Second Div */}
          <div
            className="col-5 mb-30"
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
            }}
          >
            <div
              className="post-img"
              style={{
                flex: "0 0 30%",
                textAlign: "center",
              }}
            >
              <img src={kubernetesBootcampImg} alt="Kubernetes" />
            </div>
            <div
              className="post-item"
              style={{
                flex: "1",
                paddingLeft: "15px",
              }}
            >
              <h5 className="title" style={{ fontSize: "1.5rem" }}>
                Orchestration avec Kubernetes
              </h5>
              <span className="post-date">
                Maîtrisez l'art de l'orchestration avec Kubernetes et apprenez à
                déployer, gérer et automatiser vos applications dans des
                environnements cloud complexes
              </span>
              <div className="sl-btn" style={{ paddingTop: "20px" }}>
                <Link
                  className="readon orange-btn main-home"
                  to="Bootcamps/Kubernetes"
                >
                  En savoir plus
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bootcamps;
